<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <base-v-component
      heading="Create Branch"/>

    <base-material-card
      icon="mdi-domain"
      title="Create Branch"
      class="px-5 py-3">

      <v-form ref="form" @submit="submit" onSubmit="return false;">
        <v-container class="py-0">
          <v-row>
            <v-col
              cols="12"
              md="4">

              <v-text-field
                v-model="branch.name"
                label="Branch Name"
                :rules="rules.name"
                placeholder="Branch Name"
                outlined
                required
              />
            </v-col>
            <v-col
              cols="12"
              class="text-right">
              <v-btn
                color="success"
                class="mr-0"
                @click="submit"
              >
                Add Branch
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-form>


    </base-material-card>


  </v-container>
</template>


<script>

  export default {
    name: "CreateBranch",
    created() {
    },
    data() {
      return {
        branch: {
          name: ''
        },
        rules: {
          name: [
            v => !!v || 'Name is required',
            v => v.length >= 3 || 'Name must be at least 3 characters',
          ],
        },
      }
    },
    methods: {
      submit() {
        if (!this.$refs.form.validate()) {
          return;
        }
        this.$authApi.post(`/branches`, this.branch)
          .then(res => {
            this.flashMessage.success({
              title: 'Branch Created Successfully',
              message: `Your branch ${this.branch.name} has been created successfully`
            });
            this.$router.push('/branches')

          }).catch(error => {
          if (error.response) {
            this.flashMessage.error({
              title: 'Something went wrong ',
              message: error.response.data.errors.name.join(',')
            });
          }
        })
      },

    }
  }
</script>
